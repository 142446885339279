/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';

const SafeEmail = ({ email, text }) => {
  const emailDomain = '@ryecliff.com';
  const obfuscatedEmail = `${email}&#x40;&#x72;&#x79;ec&#x6C;&#x69;&#x66;&#x66;&#x2E;&#x63;o&#x6D;`; // @ryecliff.com

  let safeText;
  if (text) {
    safeText = text.replace(`${email}${emailDomain}`, obfuscatedEmail);
  } else {
    // No text provided, just put the email
    safeText = obfuscatedEmail;
  }

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: `<a href='&#109;ai&#108;&#116;o:${obfuscatedEmail}'>${safeText}</a>`,
      }}
    />
  );
};

SafeEmail.propTypes = {
  email: PropTypes.string.isRequired, // E.g. info/sales/support
  text: PropTypes.string,
};

export default SafeEmail;
