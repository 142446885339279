import React from 'react';
import {
  Formik, Form, Field,
} from 'formik';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { BACKEND_URL } from '../config/api';


const submitContactForm = async (values) => {
  const {
    name, email, message, recaptchaValue,
  } = values;

  if (!name || !email || !message) return;

  try {
    const response = await axios.post(`${BACKEND_URL}/contact`, {
      name,
      email,
      message,
      recaptchaValue,
    });

    const { data } = response;

    console.log(data);
  } catch (error) {
    console.log(error);
  }
};

const ContactForm = () => {
  const [messageSent, setMessageSent] = React.useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <div>
      <Formik
        initialValues={{
          name: '', email: '', message: '',
        }}
        onSubmit={async (values) => {
          try {
            setMessageSent(true);

            const recaptchaValue = await executeRecaptcha('contact_page');

            // If they haven't completed the reCAPTCHA yet, do nothing.
            // This onSubmit function is called on the onChange event
            if (!recaptchaValue) return;

            await submitContactForm({
              ...values,
              recaptchaValue,
            });
          } catch (err) {
            console.log('reCaptcha service failure');
          }
        }}
      >
        <Form>
          <Field
            name="name"
            type="text"
          >
            {({ field }) => (
              <div
                className="field half first"
              >
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" {...field} />
              </div>
            )}
          </Field>
          <Field name="email">
            {({ field }) => (
              <div className="field half">
                <label htmlFor="email">Email</label>
                <input type="text" name="email" id="email" {...field} />
              </div>
            )}
          </Field>
          <Field name="message">
            {({ field }) => (
              <div className="field">
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" rows="6" {...field} />
              </div>
            )}
          </Field>
          <div className="actions">
            {!messageSent
              ? (
                <input
                  type="submit"
                  className="special"
                  value="Send Message"
                />
              ) : (
                <input
                  type="submit"
                  // className="special"
                  value="Message Sent"
                  disabled
                />
              )}
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ContactForm;
