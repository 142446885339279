import React from 'react';
import PropTypes from 'prop-types';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import '../assets/scss/main.scss';
import Header from './Header';
import Menu from './Menu';
import Contact from './Contact';
import Footer from './Footer';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuVisible: false,
      loading: 'is-loading',
    };
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  handleToggleMenu() {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible,
    });
  }

  render() {
    const { children } = this.props;

    return (
      <GoogleReCaptchaProvider
        reCaptchaKey="6LfWU7QZAAAAAAjpGsIRMhc2FhdkcHxioW0EdDJV"
      >
        <div className={`body ${this.state.loading} ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
          <div id="wrapper">
            <Header onToggleMenu={this.handleToggleMenu} />
            {children}
            <Contact />
            <Footer />
          </div>
          <Menu onToggleMenu={this.handleToggleMenu} />
        </div>
      </GoogleReCaptchaProvider>
    );
  }
}

export default Layout;
