import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import logoImage from '../assets/images/logo.png';

const useWindowSize = () => {
  const isClient = typeof window === 'object';

  const getSize = () => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  });

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

const Header = ({ onToggleMenu }) => {
  const size = useWindowSize();

  let navToRender;

  if (size.width > 742) {
    navToRender = (
      <nav>
        <span><Link to="/">Home</Link></span>
        <span><Link to="/services">Services</Link></span>
        <span><Link to="/solutions">Solutions</Link></span>
        <span><Link to="/security">Security</Link></span>
        <span><Link to="/about">About Us</Link></span>
      </nav>
    );
  } else {
    navToRender = (
      <nav>
        <a className="menu-link" onClick={onToggleMenu} href="#">Menu</a>
      </nav>
    );
  }

  return (
    <header id="header" className="alt">
      {/* <Link to="/" className="logo"><strong>Ryecliff, LLC</strong></Link> */}
      {/* <Link to="/" className="logo"><img src={logoImage} alt="Ryecliff, LLC" /></Link> */}
      {navToRender}
    </header>
  );
};

Header.propTypes = {
  onToggleMenu: PropTypes.func.isRequired,
};

export default Header;
