import React from 'react';
import axios from 'axios';

import SafeEmail from './SafeEmail';
import ContactForm from './ContactForm';

const Contact = () => (
  <section id="contact">
    <div className="inner">
      <section>
        <ContactForm />
        {/* <form method="post" action="#">
          <div className="field half first">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" />
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input type="text" name="email" id="email" />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="6" />
          </div>
          <ul className="actions">
            <li><input type="submit" value="Send Message" className="special" /></li>
            <li><input type="reset" value="Clear" /></li>
          </ul>
        </form> */}
      </section>
      <section className="split">
        <section>
          <div className="contact-method">
            <span className="icon alt fa-envelope" />
            <h3>Email</h3>
            <SafeEmail email="info" />
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-phone" />
            <h3>Phone</h3>
            <span>(818) 937-0010</span>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-lock" />
            <h3>Questions?</h3>
            <span>
Security is a major concern for businesses.
              <br />
                        The lack of security, is an even bigger concern.
              <br />
                        Let our trained professionals help you.

            </span>
          </div>
        </section>
      </section>
    </div>
  </section>
);

export default Contact;
