import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Menu = ({ onToggleMenu }) => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li><Link onClick={onToggleMenu} to="/">Home</Link></li>
        <li><Link onClick={onToggleMenu} to="/services">Services</Link></li>
        <li><Link onClick={onToggleMenu} to="/solutions">Solutions</Link></li>
        <li><Link onClick={onToggleMenu} to="/security">Security</Link></li>
        <li><Link onClick={onToggleMenu} to="/about">About Us</Link></li>
      </ul>
    </div>
    <a className="close" onClick={onToggleMenu} href="#">Close</a>
  </nav>
);

Menu.propTypes = {
  onToggleMenu: PropTypes.func.isRequired,
};

export default Menu;
