const isDev = process.env.NODE_ENV !== 'production';

let backendUrl;

if (isDev) {
  backendUrl = 'http://localhost:5000';
} else {
  backendUrl = 'https://ryecliff.com:5000';
}

export const BACKEND_URL = backendUrl;
